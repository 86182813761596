<template>
  <st-title
    :navItems="[
      {
        name: $t('studio.gnb.group_myinfo.my_group.detailed_group_per_mem_mngmt'),
        url: 'members'
      },
      {
        name: $t('studio.member_manage.join_waitlist_chk_btn')
      }
    ]"
    :title="$t('studio.member_manage.join_waitlist_chk_btn')"
  />
  <div class="mt-40">
    <st-box>
      <div class="flex gap-12 items-center">
        <div class="flex gap-8">
          <s-button
            id="history1"
            name="history"
            size="sm"
            labelValue="승인"
            variant="outline"
            @click="handleSubmit(ApprovalStatus.APPROVE)"
          >
            {{ $t('studio.member_manage.membership_requests_approve_btn') }}
          </s-button>
          <s-button
            id="history3"
            name="history"
            size="sm"
            labelValue="거절"
            variant="outline"
            @click="handleSubmit(ApprovalStatus.REJECT)"
          >
            {{ $t('studio.member_manage.membership_requests_decline_btn') }}
          </s-button>
        </div>
      </div>
      <st-table
        v-model:checkBoxValue="checkBoxValue"
        :paging="{ total, pageSize, show: true }"
        :data="data"
        :columns="columns"
        tableClass="mt-20"
        @pageChange="onPageChange"
        @onCheckboxUpdate="handleOnCheckboxUpdate"
      >
        <template #col-0="{ row }">
          <st-td aCenter>
            <checkbox
              :id="row.memberNo"
              v-model="row.selected"
              :options="{
                size: 'sm',
                align: 'middle',
                class: 'h-16 w-16'
              }"
              @update:modelValue="handleMemberSelect(row.groupMemberNo)"
            />
          </st-td>
        </template>
        <template #empty>
          <st-td colspan="4" aCenter class="h-[20rem]">
            {{ $t('studio.member_manage.join_waitlist_no_data') }}
          </st-td>
        </template>
      </st-table>
    </st-box>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { definePageMeta } from '@/.nuxt/imports';
import { approveRejectMemberRequestApi } from '@/apis/member.api';
import StBox from '@/components/common/st-box.vue';
import StTitle from '@/components/common/st-title.vue';
import StTable from '@/components/common/table/st-table.vue';
import StTd from '@/components/common/table/st-td.vue';
import Checkbox from '@/components/validation/checkbox.vue';
import { showAlert } from '@/composables/useDialog';
import { COMMON_ERROR_MESSAGE_KEY } from '@/constants/error.const';
import { ApprovalStatus } from '@/enums/common.enum';
import { MemberInviteStatus } from '@/enums/member.enum';
import { useMemberStore } from '@/stores/member.store';
import type { WaitListResponse } from '@/types/member/member-response.type';
import type { Column } from '@/types/table.type';
import { getDateTimeByLocale } from '@/utils/date.util';

definePageMeta({
  middleware: ['member-wait-list-middleware']
});

const memberStore = useMemberStore();

const { waitListData } = storeToRefs(memberStore);

const { t } = useI18n();
const route = useRoute();

const groupId = route.params.groupId as string;

const total = ref<number>(0);
const pageSize = ref<number>(0);

const memberNoList = ref<number[]>([]);

const columns = ref<Column[]>([
  {
    checkable: true,
    width: '4.8rem',
    align: 'center'
  },
  {
    title: 'studio.member_manage.join_waitlist_nickname',
    width: 'auto',
    fontWeight: 'normal',
    tooltipUse: true,
    align: 'left',
    dataIndex: 'nickname'
  },
  {
    title: 'studio.member_manage.join_waitlist_req_date',
    width: '20rem',
    align: 'left',
    fontWeight: 'normal',
    dataIndex: 'appliedAt',
    render: (row: WaitListResponse) => getDateTimeByLocale(row.appliedAt)
  },
  {
    title: 'studio.member_manage.join_waitlist_stt',
    align: 'left',
    width: '12rem',
    fontWeight: 'bold',
    dataIndex: 'inviteStatus',
    render: (row: WaitListResponse) => {
      if (row.inviteStatus === MemberInviteStatus.APPLY) {
        return t('studio.member_manage.join_waitlist_stt_waiting');
      }

      return row.inviteStatus;
    }
  }
]);

const data = ref<WaitListResponse[]>([]);

const checkBoxValue = computed(
  () => data.value.length > 0 && data.value.every((item: WaitListResponse) => item.selected)
);

const handleOnCheckboxUpdate = (value: boolean) => {
  data.value.forEach((item: WaitListResponse) => {
    if (value) {
      item.selected = true;
    } else {
      item.selected = false;
    }

    handleMemberSelect(item.groupMemberNo);
  });
};

const handleSubmit = async (requestType: ApprovalStatus) => {
  if (!memberNoList.value.length) {
    await showAlert({
      content: t('studio.member_manage.membership_requests_action_alert_msg')
    });
    return;
  }

  try {
    const res = await approveRejectMemberRequestApi({
      groupId,
      groupMemberNos: memberNoList.value,
      requestType
    });

    if (!res) {
      await showAlert({
        severity: 'info',
        content: t(COMMON_ERROR_MESSAGE_KEY)
      });

      return;
    }

    if (
      (res.failGroupMembers.length > 0 && memberNoList.value.length === 1) ||
      res.failGroupMembers.length === memberNoList.value.length
    ) {
      await showAlert({
        severity: 'info',
        content: t(COMMON_ERROR_MESSAGE_KEY)
      });
    }

    if (res.successGroupMembers.length > 0) {
      if (requestType === ApprovalStatus.APPROVE) {
        await showAlert({
          content: t('studio.member_manage.membership_requests_approve_alert_msg')
        });
      } else {
        await showAlert({
          content: t('studio.member_manage.membership_requests_decline_alert_msg')
        });
      }
    }

    await memberStore.fetchWaitList(1);

    await init();
  } catch (error) {
    // console.error(error);
  }
};

const onPageChange = async (page: number) => {
  await memberStore.fetchWaitList(page);
  init();
};

const handleMemberSelect = (groupMemberNo: number) => {
  const member = data.value.find((item: WaitListResponse) => item.groupMemberNo === groupMemberNo);

  if (member) {
    if (member.selected) {
      memberNoList.value.push(groupMemberNo);

      return;
    }

    memberNoList.value = memberNoList.value.filter((item: number) => item !== groupMemberNo);
  }
};

const init = async () => {
  total.value = waitListData.value?.totalElements ?? 0;
  pageSize.value = waitListData.value?.size ?? 0;

  data.value = waitListData.value?.contents ?? [];
  data.value.forEach((item: WaitListResponse) => {
    item.selected = false;
  });
};

init();
</script>
